import { ComponentInstance, computed, watch } from "@vue/composition-api";

const useOpenHistoryMode = (root: ComponentInstance, moduleName: string, showModal?: () => void, openIdMethod = "addOpenedId"): void => {
  const store = root.$store;
  const cb = (id: string) => {
    if (id) {
      store.dispatch("auth/getUserHistoryById", id).then(() => {
        const historyItem = store.getters["auth/historyItem"];
        if (historyItem) {
          store.commit(`${moduleName}/setFilters`, historyItem.filters);
          store.commit(`${moduleName}/${openIdMethod}`, historyItem.item.id);
          store.dispatch(`${moduleName}/refreshScroll`);

          showModal?.();
        }
      });
    }
  };
  const historyId = computed((): string => root.$route.query.historyId as string);
  // const historyEadId = computed((): string => root.$route.query.historyEadId as string);
  // const historyInventoryId = computed((): string => root.$route.query.historyInventoryId as string);
  watch(historyId, (id: string) => cb(id), {
    immediate: true,
  });
  // watch(historyEadId, (id: string) => cb(id, "eadHistory"), {
  //   immediate: true,
  // });
  // watch(historyInventoryId, (id: string) => cb(id, "inventoryHistory"), {
  //   immediate: true,
  // });
};

export default useOpenHistoryMode;
