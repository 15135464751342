







































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "YearPicker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    attach: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "гггг",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      default: "datapicker-input",
    },
    max: {
      type: String,
      default: new Date().getFullYear().toString(),
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    clearDate() {
      this.menu = false;
      this.$emit("change", "");
    },
    saveYear(year: number) {
      this.$emit("change", year.toString() || "");
      this.menu = false;
    },
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => ((this.$refs.picker as unknown as { internalActivePicker?: string }).internalActivePicker = "YEAR"));
    },
  },
});
