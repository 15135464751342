






































import { defineComponent, computed } from "@vue/composition-api";
import draggable from "vuedraggable";
import SortPanel from "./SortPanel.vue";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";

type headerMin = { width: number; id: string; value: string; isTextAlignCenter?: boolean; isHandle?: boolean };
export default defineComponent({
  name: "TableHeader",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeCells: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    defaultTableWidth: {
      type: Number,
      default: 1120,
    },
    isNotStretchLastHeader: {
      type: Boolean,
      default: false,
    },
    customFieldNames: {
      type: Object,
      default: () => ({}),
    },
    getFormattedHeadersCb: {
      type: Function,
    },
  },
  data() {
    return {
      tooltipZIndex: 105,
    };
  },
  components: {
    SortPanel,
    draggable,
  },
  computed: {
    headers: {
      get(this: { stretchHeaders: (tableHeaders: headerMin[]) => headerMin[]; activeCells: string[]; tableHeaders: headerMin[] }): headerMin[] {
        if (Array.isArray(this.activeCells) && this.activeCells.length) {
          const obj = (this.tableHeaders as headerMin[]).reduce((res: Record<string, headerMin>, el: headerMin) => {
            res[el.value] = el;
            return res;
          }, {});
          const currentHeaders = this.activeCells
            .map((name: string) => {
              return obj[name] || null;
            })
            .filter((item: unknown) => !!item);
          return this.stretchHeaders(currentHeaders);
        } else {
          return [...this.stretchHeaders(this.tableHeaders)];
        }
      },
      set(value: headerMin[]) {
        const resultArray = this.checkCheckboxOnFirstPosition([...value]);
        this.$emit("setCells", resultArray);
      },
    },
  },
  methods: {
    activateSelection(value: boolean, header: headerMin) {
      this.$emit(value ? "activateSelection" : "deactivateSelection", {
        header,
        headers: this.headers,
        scrollLeft: document.getElementById("table-header-wrap")?.scrollLeft ?? 0,
      });
    },
    checkCheckboxOnFirstPosition(value: headerMin[]) {
      return value.sort((element) => (element.value === "checkbox" ? -1 : 0));
    },
    onScrollCb(e: Event) {
      const element = document.getElementById("virtual-scroll-table");
      if (element) {
        element.scrollLeft = (e.target as HTMLElement)?.scrollLeft;
      }
    },
    setHeaderClass(header: headerMin) {
      return [
        "th",
        "pr-3 pt-0 pb-0 pl-3",
        {
          "text-left": !header.isTextAlignCenter,
          "text-center": header.isTextAlignCenter,
          "pl-0": header.value === "type",
          "pl-3": header.value !== "type",
          handle: header.isHandle,
        },
      ];
    },
    toggleSelectAll() {
      this.$emit("change", !this.value);
    },
  },
  setup(props, { root }) {
    const tableHeaders = computed(() => props.tableHeaders as unknown[]);
    const { stretchHeaders } = useTableCells(
      root,
      "",
      tableHeaders,
      props.defaultTableWidth,
      props.isNotStretchLastHeader,
      false,
      props.customFieldNames,
      props.getFormattedHeadersCb
    );
    return {
      stretchHeaders,
    };
  },
});
