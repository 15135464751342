





























import { defineComponent } from "@vue/composition-api";
import mask from "@monorepo/utils/src/directives/mask";

export default defineComponent({
  name: "TextFilter",
  directives: {
    mask,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    mask: {
      type: Object,
    },
    type: {
      type: String,
      default: "text",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    pattern: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeValue(val: string) {
      this.$emit("change", val || "");
    },
    inputValue(val: string) {
      this.$emit("input", val || "");
    },
  },
});
