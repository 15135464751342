export interface IPassportArchiveReport {
  FILTER_ARCHIVE: string;

  TABLE_COLUMN_NUMBER: string;
  TABLE_COLUMN_GROUP: string;
  TABLE_COLUMN_TOTAL: string;

  TABLE_TOTAL_FUND: string;
  TABLE_TOTAL_EAD: string;
  TABLE_TOTAL_INVENTORY: string;

  TABLE_TOTAL_FUND_YEAR: string;
  TABLE_TOTAL_RETIRED_FUND_YEAR: string;

  TABLE_IN_FULL_COPIES: string;
  TABLE_TOTAL_INVENTORY_YEAR: string;
  TABLE_TOTAL_RETIRED_INVENTORY_YEAR: string;
}

export const fields: IPassportArchiveReport = {
  FILTER_ARCHIVE: "name",

  TABLE_COLUMN_NUMBER: "column-number",
  TABLE_COLUMN_GROUP: "column-group",
  TABLE_COLUMN_TOTAL: "column-total",

  TABLE_TOTAL_FUND: "tmp2",
  TABLE_TOTAL_EAD: "tmp3",
  TABLE_TOTAL_INVENTORY: "tmp4",

  TABLE_TOTAL_FUND_YEAR: "tmp5",
  TABLE_TOTAL_RETIRED_FUND_YEAR: "tmp6",

  TABLE_IN_FULL_COPIES: "tmp7",
  TABLE_TOTAL_INVENTORY_YEAR: "tmp8",
  TABLE_TOTAL_RETIRED_INVENTORY_YEAR: "tmp9",
};
