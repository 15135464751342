export enum GeneratedIntervalTypes {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

type GeneratedInterval = keyof typeof GeneratedIntervalTypes;

export interface IScheduledReport {
  authorUsername: string;
  name: string;
  id: number | string;
  searchTemplateGuid: number | string;
  generateInterval: GeneratedInterval;
  active: boolean;
}
