import { DirectiveOptions } from "vue";
import Inputmask from "inputmask";

const elements = ["input"];

const findInputElement = (el: HTMLElement): HTMLInputElement | null => {
  if (elements.includes(el.tagName.toLowerCase())) {
    return el as HTMLInputElement;
  }

  return elements.reduce((result, element) => {
    return el.querySelector(element) || result;
  }, null as null | HTMLInputElement);
};

const initMask = (inputEl: HTMLInputElement, value: string) => {
  const im = new Inputmask(value || "");
  im.option({
    onBeforePaste: function (val: string) {
      if (inputEl) {
        setTimeout(() => {
          inputEl.dispatchEvent(new Event("input"));
        }, 50);
      }
      return val;
    },
  });
  im.mask(inputEl);
};

const mask: DirectiveOptions = {
  bind(el, node) {
    const inputEl = findInputElement(el);
    if (!inputEl) {
      return;
    }
    initMask(inputEl, node.value);
  },
  update(el, node) {
    const inputEl = findInputElement(el);
    if (!inputEl) {
      return;
    }
    initMask(inputEl, node.value);
  },
  unbind(el) {
    const inputEl = findInputElement(el);
    if (!inputEl) {
      return;
    }
    inputEl.inputmask?.remove?.();
  },
};

export default mask;
