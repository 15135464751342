import { ITableFiltersObject } from "../store/types/tableFiltersObject";
import { cloneDeep, get, isEmpty } from "lodash";

const generateFiltersEntries = (filters: ITableFiltersObject) => {
  return Object.entries(filters.fieldFilters)
    .filter(([, value]) => !isEmpty(value) || typeof value === "number" || (typeof value === "boolean" && value))
    .map(([key, value]) => {
      return {
        key,
        value: Array.isArray(value) ? value : [value],
      };
    });
};

const generateSortEntries = (filters: ITableFiltersObject) => {
  if (!filters.sort) {
    return;
  }
  return Object.entries(filters.sort || {})
    .filter(([, value]) => !isEmpty(value))
    .map(([field, direction]) => {
      return {
        field,
        direction,
      };
    });
};

export const convertAutocompleteItemToApi = (resultFilters: ITableFiltersObject, resultFilterKey: string, keyToFilterItem: string): void => {
  const element = get(resultFilters, keyToFilterItem);
  if (element) {
    resultFilters.fieldFilters[resultFilterKey] = element.toString();
  }
};

export const convertTreeItemsToApi = (resultFilters: ITableFiltersObject, filterKey: string, filterItemFieldName: string): void => {
  if (Array.isArray(resultFilters.fieldFilters[filterKey]) && (resultFilters.fieldFilters[filterKey] as any[])?.length) {
    resultFilters.fieldFilters[filterKey] = (resultFilters.fieldFilters[filterKey] as any[]).map((item) => item[filterItemFieldName]);
  }
};

export const convertOikCodeFilter = (resultFilters: ITableFiltersObject): void => {
  if ((resultFilters.fieldFilters as { code?: { code?: string } }).code?.code) {
    resultFilters.fieldFilters.code = (resultFilters.fieldFilters as { code?: { code?: string } }).code?.code as string;
  }

  if ((resultFilters.fieldFilters as { oikCode?: { code?: string } }).oikCode?.code) {
    resultFilters.fieldFilters.oikCode = (resultFilters.fieldFilters as { oikCode?: { code?: string } }).oikCode?.code as string;
  }
};

export const convertIdFromCodeFilter = (resultFilters: ITableFiltersObject): void => {
  if ((resultFilters.fieldFilters as { oikCode?: { code?: string } }).oikCode?.code) {
    resultFilters.fieldFilters.id = (resultFilters.fieldFilters as { oikCode?: { code?: string } }).oikCode?.code as string;
  }
};

export const formDateFilter = (resultFilters: ITableFiltersObject, key: string): void => {
  if (resultFilters.fieldFilters[`${key}From`] || resultFilters.fieldFilters[`${key}To`]) {
    resultFilters.fieldFilters[key] = [
      (resultFilters.fieldFilters[`${key}From`] as string) ?? "",
      (resultFilters.fieldFilters[`${key}To`] as string) ?? "",
    ];
    delete resultFilters.fieldFilters[`${key}From`];
    delete resultFilters.fieldFilters[`${key}To`];
  }
};

export const formTimeFilter = (resultFilters: ITableFiltersObject, key: string): void => {
  const from = (resultFilters.fieldFilters[`${key}From`] as string) ?? "";
  const to = (resultFilters.fieldFilters[`${key}To`] as string) ?? "";
  if (from || to) {
    resultFilters.fieldFilters[key] = [from ? `${from}:00` : "", to ? `${to}:00` : ""];
    delete resultFilters.fieldFilters[`${key}From`];
    delete resultFilters.fieldFilters[`${key}To`];
  }
};

export const formDateAndTimeFilter = (resultFilters: ITableFiltersObject, dateKey: string, timeKey: string): void => {
  if (resultFilters.fieldFilters[`${dateKey}From`] || resultFilters.fieldFilters[`${dateKey}To`]) {
    const startDate = resultFilters.fieldFilters[`${dateKey}From`]
      ? `${resultFilters.fieldFilters[`${dateKey}From`]}T${(resultFilters.fieldFilters[`${timeKey}From`] as string) || "00:00"}:00`
      : "";
    const endDate = resultFilters.fieldFilters[`${dateKey}To`]
      ? `${resultFilters.fieldFilters[`${dateKey}To`]}T${(resultFilters.fieldFilters[`${timeKey}To`] as string) || "23:59"}:59`
      : "";
    resultFilters.fieldFilters[dateKey] = [startDate, endDate];
    delete resultFilters.fieldFilters[`${dateKey}From`];
    delete resultFilters.fieldFilters[`${dateKey}To`];
    delete resultFilters.fieldFilters[`${timeKey}From`];
    delete resultFilters.fieldFilters[`${timeKey}To`];
  }
};

export interface IConvertFiltersToApiResponse {
  filters: ReturnType<typeof generateFiltersEntries>;
  sort?: ReturnType<typeof generateSortEntries>;
  cells?: string[];
  offset?: number;
  limit?: number;
}

export const convertFiltersToApi = (
  filters: ITableFiltersObject,
  loadedLength: number,
  customFiltersCb?: (filters: ITableFiltersObject) => void,
  isWithoutPagination?: boolean,
  isWithoutSort?: boolean
): IConvertFiltersToApiResponse => {
  const resultFilters: ITableFiltersObject = cloneDeep(filters);
  customFiltersCb?.(resultFilters);

  const fieldFiltersEntries = generateFiltersEntries(resultFilters);

  const sortFiltersEntries = generateSortEntries(resultFilters);

  return {
    filters: fieldFiltersEntries,
    ...(isWithoutSort
      ? {}
      : {
          sort: sortFiltersEntries,
        }),
    ...(isWithoutPagination
      ? {}
      : {
          offset: loadedLength,
          limit: filters.initMessagesLength,
        }),
  };
};
