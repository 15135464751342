






import { defineComponent, PropType } from "@vue/composition-api";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

export default defineComponent({
  name: "SortPanel",
  props: {
    id: {
      type: String,
      default: "",
    },
    sortObject: {
      type: Object as PropType<Record<string, SORT_TYPE>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      SORT_TYPE,
    };
  },
  computed: {
    sortType(): SORT_TYPE | null {
      if (this.sortObject?.[this.id]) {
        return this.sortObject[this.id];
      }
      return null;
    },
    sortTypeIcon(): string {
      if (!this.sortType) {
        return this.getIconPath("both");
      }

      return this.sortType === SORT_TYPE.ASC ? this.getIconPath("top") : this.getIconPath("bottom");
    },
  },
  methods: {
    getIconPath(name: string): string {
      return `${environmentVariables.BASE_URL}assets/images/sortIcons/${name}.svg`;
    },
    changeSort(): void {
      let currentType;
      if (!this.sortType) {
        currentType = SORT_TYPE.DESC;
      } else if (this.sortType === SORT_TYPE.DESC) {
        currentType = SORT_TYPE.ASC;
      } else {
        currentType = "";
      }
      this.$emit("changeSort", currentType);
    },
  },
});
