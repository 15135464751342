export const educationIds = {
  toggleTableCells: "toggle-cells",
  filterTemplates: "filter-templates",
  toggleFilters: "toggle-filters",
  addElementUser: "add-element-user",
  addElementFund: "add-element-fund",
  addElementArchive: "add-element-archive",
  addElementOik: "add-element-oik",
  addElementNotification: "add-element-notification",
  numberOfEvents: "number-of-events",
  generateTk: "generate-tk",
  generateProjectTk: "generate-project-tk",
  transferTk: "transfer-tk",
  transferProjectTk: "transfer-project-tk",
  transferEadTk: "transfer-ead-tk",
  clearFolder: "clear-folder",
  clearFolderTk: "clear-folder-tk",
  gant: "gant",
  clearTkInfo: "clear-tk-info",
  clearAllEntities: "clear-all-entities",
  clearAllCatalogs: "clear-all-catalogs",
  addDefaultCatalogs: "add-default-catalogs",
  listViewSettings: "list-view-settings",
  formListView: "form-list-view",
  exportListView: "export-list-view",
  servicesState: "services-state",
  timeGraphSettings: "time-graph-settings",
  generalSectionSettings: "GENERAL_SECTION",
  administrationSettings: "vsh-administration-settings",
  containerWorkerSettings: "vsh-container-worker-settings",
  dictionarySettings: "vsh-dictionary-settings",
  documentRegistrationSettings: "vsh-document-registration-settings",
  filestorageSettings: "vsh-filestorage-settings",
  hduoIntegrationSettings: "vsh-hduo-integration-settings",
  informationRetrievalSettings: "vsh-information-retrieval-settings",
  informationSecuritySettings: "vsh-information-security-settings",
  reportSettings: "vsh-report-settings",
  tkLogType: "tk-log-type",
  addRole: "add-role",
  deleteRole: "delete-role",
  showRoleSettings: "show-role-settings",
  roleUsersList: "role-users-list",
  rolesTab: "roles-tab",
  usersTab: "users-tab",
  syncLogOikTab: "sync-log-oik-tab",
  syncLogJournalTab: "sync-log-journal-tab",
  treeViewTab: "tree-view-tab",
  tableViewTab: "table-view-tab",
};
