










import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "FilterLabel",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object as PropType<{ title: string; tooltip?: string }>,
      default: "",
    },
  },
});
