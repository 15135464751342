import { IReportElement } from "@monorepo/reports/src/views/PassportArchiveStoreReportView/types/reportElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/passportArchiveStoreReport/fields";

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

export const convertApiItemToUi = (item: IReportElement): Record<Values, string | number> => ({
  [fields.TABLE_TOTAL_FUND]: item.fundCount || "",
  [fields.TABLE_TOTAL_EAD]: item.eadCount || "",
  [fields.TABLE_TOTAL_INVENTORY]: item.eadInInventoryCount || "",

  [fields.TABLE_TOTAL_FUND_YEAR]: item.newFundAndEadCount || "",
  [fields.TABLE_TOTAL_RETIRED_FUND_YEAR]: "0",
  [fields.TABLE_IN_FULL_COPIES]: item.inventoryCount || "",
  [fields.TABLE_TOTAL_INVENTORY_YEAR]: item.inventoryFullCount || "",
  [fields.TABLE_TOTAL_RETIRED_INVENTORY_YEAR]: item.newInventoryCount || "",
});
