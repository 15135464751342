

































import { defineComponent } from "@vue/composition-api";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import FiltersTemplatesList from "./FiltersTemplatesList.vue";
import FiltersAutoExport from "./FiltersAutoExport.vue";
import { GeneratedIntervalTypes } from "@monorepo/utils/src/types/IScheduledReport";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

const icon = `${environmentVariables.BASE_URL}assets/images/common/template-filters.svg`;

const Views = {
  SAVE_TEMPLATES: "FiltersTemplatesList",
  AUTO_EXPORT: "FiltersAutoExport",
};

export default defineComponent({
  name: "TemplateFilterButton",
  components: {
    FiltersTemplatesList,
    FiltersAutoExport,
  },
  data() {
    return {
      educationIds,
      icon,
      isOpen: false,
      step: Views.SAVE_TEMPLATES,
      Views,
      currentTemplateId: null as string | number | null,
    };
  },
  methods: {
    toggleView(view: string, id: number | string) {
      this.currentTemplateId = id;
      this.step = view;
    },
    toggle(event: boolean) {
      if (!event && this.step === Views.SAVE_TEMPLATES) {
        (this.$refs.component as unknown as { forceUpdate: () => void })?.forceUpdate();
      }
    },
  },
  props: {
    exceptItems: {
      type: Array,
      default: () => [GeneratedIntervalTypes.YEARLY],
    },
    sectionName: {
      type: String,
      default: "",
    },
    isShowAutoButton: {
      type: Boolean,
      default: false,
    },
  },
});
