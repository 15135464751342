















import { defineComponent } from "@vue/composition-api";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "ToggleFilters",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isLoadingToggleFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      educationIds,
    };
  },
});
