









import Vue from "vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ScrollPanel",
  data() {
    return {
      isChangeBackground: false,
      hasHeadersScrollBar: false,
    };
  },
  methods: {
    changeBackground(value: boolean) {
      this.isChangeBackground = value;
    },
  },
  updated: function () {
    Vue.nextTick(() => {
      const element = document.getElementById("table-header-wrap");

      if (element) {
        this.hasHeadersScrollBar = element.scrollWidth > element.clientWidth;
      }
    });
  },
  mounted() {
    const triggerElement: Element | null = document.querySelector("[data-scroll-start-marker]");
    const observer = new IntersectionObserver(
      ([e]) => {
        this.changeBackground(!e.intersectionRatio);
      },
      {
        threshold: [0.001],
      }
    );
    if (triggerElement) {
      observer.observe(triggerElement as Element);
    }
  },
});
