

















import { defineComponent } from "@vue/composition-api";
import { GeneratedIntervalTypes } from "@monorepo/utils/src/types/IScheduledReport";
import TemplateFilterButton from "./TemplateFilterButton.vue";

export default defineComponent({
  name: "FindButton",
  components: {
    TemplateFilterButton,
  },
  props: {
    exceptItems: {
      type: Array,
      default: () => [GeneratedIntervalTypes.YEARLY],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Найти",
    },
    isShowTemplates: {
      type: Boolean,
      default: true,
    },
    sectionName: {
      type: String,
      default: "",
    },
    isShowAutoButton: {
      type: Boolean,
      default: false,
    },
  },
});
