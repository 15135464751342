







































import { defineComponent, ref } from "@vue/composition-api";
import Loading from "@monorepo/uikit/src/components/tableViews/Loading.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { FILTER_TEMPLATES_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/filterTemplates";
import { formLoadingPayload } from "@monorepo/utils/src/eventBus/utils/formFiltersTemplatePayload";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";

type filter = {
  id: string;
  title: string;
};

type data = {
  isNewEdit: boolean;
  title: string;
  items: filter[];
};
export default defineComponent({
  name: "FiltersTemplatesList",
  components: {
    Loading,
  },
  data(): data {
    return {
      isNewEdit: false,
      title: "",
      items: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    templates(): ISearchTemplate[] {
      return this.$store.getters[`${this.sectionName}/searchTemplates`];
    },
    isShowAutoGenerated(): boolean {
      return isAuthorityExist(this.user, authorities.SCHEDULED_REPORT_LIST);
    },
  },
  setup(props, { root }) {
    const isLoading = ref(false);
    if (!root.$store.getters[`${props.sectionName}/searchTemplates`]?.length) {
      isLoading.value = true;
      root.$store.dispatch(`${props.sectionName}/getSearchTemplates`).finally(() => {
        isLoading.value = false;
      });
    }
    return { isLoading };
  },
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    views: {
      type: Object,
      required: true,
    },
    isShowAutoButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleNew(isNewEdit: boolean) {
      this.isNewEdit = isNewEdit;
    },
    restoreFilter(template: Partial<ISearchTemplate>) {
      eventBus.$emit(FILTER_TEMPLATES_BUS_ACTIONS.RESTORE_FILTER, formLoadingPayload(FILTER_TEMPLATES_BUS_ACTIONS.SAVE_FILTERS, template));
      this.$emit("select");
    },
    forceUpdate() {
      this.title = "";
      this.isNewEdit = false;
    },
    save() {
      if (!this.title) {
        return false;
      }
      const template: Partial<ISearchTemplate> = {
        templateName: this.title,
      };
      eventBus.$emit(FILTER_TEMPLATES_BUS_ACTIONS.SAVE_FILTERS, formLoadingPayload(FILTER_TEMPLATES_BUS_ACTIONS.SAVE_FILTERS, template));
      this.isNewEdit = false;
      this.title = "";
    },
    deleteCb(template: ISearchTemplate) {
      this.$store.dispatch(`${this.sectionName}/deleteSearchTemplates`, template);
    },
  },
});
