import { ComponentInstance, computed } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

const useInitEducationPanel = (root: ComponentInstance): void => {
  const store = root.$store;
  const localStorageValue = "isShownEducation";

  const openEducationPanel = (isAuth: boolean) => {
    if (!localStorage.getItem(localStorageValue) && isAuth) {
      localStorage.setItem(localStorageValue, "1");
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "EducationPanelModal"));
    }
  };
  const isAuth = computed(() => store.getters["auth/isAuth"]);

  openEducationPanel(isAuth.value);
};

export default useInitEducationPanel;
