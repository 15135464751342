































import { defineComponent } from "@vue/composition-api";
import TimePicker from "@monorepo/uikit/src/components/tableViews/TimePicker.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";

export default defineComponent({
  name: "DateTimePeriod",
  components: {
    TimePicker,
    DatePicker,
  },
  model: {
    prop: "currentFilters",
    event: "change",
  },
  props: {
    currentFilters: {
      type: Object,
      default: () => ({}),
    },
    isTableLoading: {
      type: Boolean,
      default: false,
    },
    filterItem: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dateFromKey(): string {
      return this.filterItem.dateValues[0];
    },
    dateToKey(): string {
      return this.filterItem.dateValues[1];
    },
    timeFromKey(): string {
      return this.filterItem.timeValues[0];
    },
    timeToKey(): string {
      return this.filterItem.timeValues[1];
    },
  },
  methods: {
    changeDateTo() {
      if (this.currentFilters[this.dateToKey] && !this.currentFilters[this.timeToKey]) {
        this.currentFilters[this.timeToKey] = "23:59";
      } else if (!this.currentFilters[this.dateToKey]) {
        this.currentFilters[this.timeToKey] = "";
      }
    },
    changeDateFrom() {
      if (this.currentFilters[this.dateFromKey] && !this.currentFilters[this.timeFromKey]) {
        this.currentFilters[this.timeFromKey] = "00:00";
      } else if (!this.currentFilters[this.dateFromKey]) {
        this.currentFilters[this.timeFromKey] = "";
        this.currentFilters[this.dateToKey] = "";
        this.currentFilters[this.timeToKey] = "";
      }
    },
  },
});
