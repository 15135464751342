











import { defineComponent } from "@vue/composition-api";
import { getPluralNoun } from "@monorepo/utils/src/utils/pluralNoun";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "TableSettings",
  props: {
    loadedCount: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isAdditionalRight: {
      type: Boolean,
      default: true,
    },
    pluralArr: {
      type: Array,
      default() {
        return ["записи", "записей", "записей"];
      },
    },
  },
  data() {
    return {
      educationIds,
    };
  },
  computed: {
    recordsTitle(): string {
      return getPluralNoun(this.totalCount, ...(this.pluralArr as string[]));
    },
  },
});
