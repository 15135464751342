export interface IReceiptDocumentsBookReport {
  FILTER_ARCHIVE: string;
  FILTER_PERIOD: string;
  FILTER_PERIOD_FROM: string;
  FILTER_PERIOD_TO: string;

  TABLE_ID: string;
  TABLE_NUMBER: string;
  TABLE_DATE_RECEIVED: string;
  TABLE_ACT_NUMBER: string;
  TABLE_OIK: string;
  TABLE_INVENTORY_NUMBER: string;
  TABLE_INVENTORY_YEAR: string;
  TABLE_INVENTORY_TITLE: string;
  TABLE_FUND_NUMBER: string;
  TABLE_DOC_DURATION: string;
  TABLE_COUNT: string;
}

export const fields: IReceiptDocumentsBookReport = {
  FILTER_ARCHIVE: "archiveCode",
  FILTER_PERIOD: "creationDateWithoutTime",
  FILTER_PERIOD_FROM: "creationDateWithoutTimeFrom",
  FILTER_PERIOD_TO: "creationDateWithoutTimeTo",

  TABLE_ID: "id",
  TABLE_NUMBER: "index",
  TABLE_DATE_RECEIVED: "registrationDate",
  TABLE_ACT_NUMBER: "number",
  TABLE_OIK: "oikName",
  TABLE_INVENTORY_NUMBER: "caseNumber",
  TABLE_INVENTORY_YEAR: "caseDate",
  TABLE_INVENTORY_TITLE: "caseHeader",
  TABLE_FUND_NUMBER: "fundName",
  TABLE_DOC_DURATION: "years",
  TABLE_COUNT: "eadFileCount",
};
