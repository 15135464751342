

























import { defineComponent } from "@vue/composition-api";
import Loading from "@monorepo/uikit/src/components/tableViews/Loading.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ExportButton",
  components: {
    Loading,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters("app", ["isExportFileLoading"]),
  },
  props: {
    types: {
      type: Array,
      default() {
        return ["CSV", "PDF", "XLSX"];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSelectAll: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getExportDataCb(type: string) {
      this.menu = false;
      this.$emit("getExportData", type);
    },
  },
});
