import { ComponentInstance, onMounted, onUnmounted, Ref, computed } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { FILTER_TEMPLATES_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/filterTemplates";
import { IActionType } from "@monorepo/utils/src/eventBus/types/action";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { isEqual, isEmpty } from "lodash";
import { debounce } from "lodash";

interface IUseAutocomplete {
  getAutocompleteItems: (value: string, key: string, cb: (value: string) => Promise<Record<string, unknown>[]>) => void;
}

const useAutocomplete = (
  root: ComponentInstance,
  moduleName: string,
  currentFilters: Ref<Record<string, unknown>>,
  autocompleteItemsResultLists: Ref<{ [key: string]: Record<string, unknown>[] }>,
  isLoadingAutocomplete: Ref<boolean>,
  autocompleteFiltersKeys: string[],
  customFieldNames?: { searchTemplates?: string }
): IUseAutocomplete => {
  const store = root.$store;

  const searchTemplates = computed(
    () => (store.getters[`${moduleName}/${customFieldNames?.searchTemplates || "searchTemplates"}`] || []) as { id: string; value: string }[]
  );

  const restoreAutocompleteFilters = (packet: IActionType<ISearchTemplate>) => {
    const currentFilters = JSON.parse(searchTemplates.value?.find((item) => item.id === packet.payload.id)?.value || "{}");
    if (currentFilters) {
      isLoadingAutocomplete.value = true;
      autocompleteFiltersKeys.forEach((key) => {
        if (!isEmpty(currentFilters[key])) {
          autocompleteItemsResultLists.value[key] = [currentFilters[key]];
        }
      });
      isLoadingAutocomplete.value = false;
    }
  };

  const restoreAutocompleteLists = () => {
    autocompleteFiltersKeys.forEach((key) => {
      if (!isEmpty(currentFilters.value[key])) {
        autocompleteItemsResultLists.value[key] = [currentFilters.value[key] as Record<string, unknown>];
      } else {
        autocompleteItemsResultLists.value[key] = [];
      }
    });
  };

  const getAutocompleteItems = debounce(async (value: string, key: string, cb: (value: string) => Promise<Record<string, unknown>[]>) => {
    isLoadingAutocomplete.value = true;
    autocompleteItemsResultLists.value[key] = (await cb(value)) || [];
    if (currentFilters.value[key] && !value) {
      const isEqualExist = !!autocompleteItemsResultLists.value[key].find((item: unknown) => isEqual(item, currentFilters.value[key]));
      autocompleteItemsResultLists.value[key] = autocompleteItemsResultLists.value[key].concat(
        (isEqualExist || isEmpty(currentFilters.value[key]) ? [] : currentFilters.value[key]) as any
      );
    }
    isLoadingAutocomplete.value = false;
  }, 300);

  onMounted(() => {
    eventBus.$on(FILTER_TEMPLATES_BUS_ACTIONS.RESTORE_FILTER, restoreAutocompleteFilters);
    restoreAutocompleteLists();
  });

  onUnmounted(() => {
    eventBus.$off(FILTER_TEMPLATES_BUS_ACTIONS.RESTORE_FILTER, restoreAutocompleteFilters);
  });

  return {
    getAutocompleteItems,
  };
};

export default useAutocomplete;
