import { ComponentInstance, computed, onMounted, ComputedRef, ref } from "@vue/composition-api";
import Vue from "vue";
import { cloneDeep } from "lodash";

type headerMin = { width: number; defaultWidth: number; id: string; value: string; isLink: boolean; linkRights: string[] };

interface IUseTableCells {
  headers: ComputedRef<headerMin[]>;
  stretchHeaders: ComputedRef<(tableHeaders: headerMin[]) => headerMin[]>;
  getTableWidth: () => void;
  getFormattedHeaders: (headers: headerMin[], visibleHeaders: headerMin[], difference: number) => headerMin[];
}

const useTableCells = (
  root: ComponentInstance,
  moduleName: string,
  tableHeaders: ComputedRef<unknown[]>,
  defaultTableWidth = 1120,
  isNotStretchLastHeader = false,
  isTopTable = false,
  customFieldNames?: { getCellsName?: string },
  getFormattedHeadersCb?: (headers: headerMin[], visibleHeaders: headerMin[], difference: number) => headerMin[]
): IUseTableCells => {
  const store = root.$store;

  const tableWidth = ref(defaultTableWidth);

  const getCells = computed(() => (isTopTable ? [] : store.getters[`${moduleName}/${customFieldNames?.getCellsName || "cells"}`] || []));

  const user = computed(() => store.getters["auth/user"]);

  const tableColumns = computed(() => {
    return cloneDeep(tableHeaders.value as headerMin[]);
  });

  const stretchHeaders = computed(() => {
    return (tableHeaders: headerMin[]): headerMin[] => {
      const { headers, visibleHeaders, difference } = formVisibleHeaders(tableHeaders);
      const headersWithResultLinks = formResultLinks(headers);
      return getFormattedHeaders(headersWithResultLinks, visibleHeaders, difference);
    };
  });

  const authoritiesObj = computed(() => {
    return user.value?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
      result[item.authority || ""] = true;
      return result;
    }, {});
  });

  const tableHeadersObj = computed(() => {
    return (tableHeaders.value as headerMin[]).reduce((res: Record<string, headerMin>, el) => {
      res[el.value] = el;
      return res;
    }, {});
  });

  const headers = computed(() => {
    if (Array.isArray(getCells.value) && getCells.value.length) {
      const currentHeaders = getCells.value
        .map((name: string) => {
          return tableHeadersObj.value[name];
        })
        .filter((item: headerMin | undefined) => !!item);
      return stretchHeaders.value(currentHeaders);
    } else {
      return [...stretchHeaders.value(tableColumns.value)];
    }
  });

  const formVisibleHeadersFirst = (tableHeaders: headerMin[]) => {
    let counter = 0;
    let headers = cloneDeep(tableHeaders);
    headers = headers.map((item) => ({ ...item, width: item.defaultWidth }));
    const visibleHeaders = headers
      .map((item: headerMin) => {
        if (counter >= tableWidth.value) {
          return;
        }
        if (item.defaultWidth) {
          counter += item.defaultWidth;
        }
        return item;
      })
      .filter((item: headerMin | undefined): item is headerMin => Boolean(item));

    const difference: number = tableWidth.value - counter;
    return { startCounter: counter, headers, visibleHeaders, startDifference: difference };
  };

  const formVisibleHeaders = (tableHeaders: headerMin[]) => {
    const { startCounter, headers, visibleHeaders, startDifference } = formVisibleHeadersFirst(tableHeaders);
    let counter = startCounter,
      difference = startDifference;

    if (counter > tableWidth.value) {
      counter = 0;
      visibleHeaders.pop();
      visibleHeaders.forEach((item: headerMin) => {
        if (item.defaultWidth) {
          counter += item.defaultWidth;
        }
      });
      difference = tableWidth.value - counter;
    }

    return { headers, visibleHeaders, difference };
  };

  const formResultLinks = (headers: headerMin[]) => {
    return headers.map((header) => {
      if (!header.linkRights?.length) {
        return header;
      }
      return {
        ...header,
        isLink: header?.isLink ? header?.linkRights?.some((key) => authoritiesObj?.value?.[key]) : false,
      };
    });
  };

  const getTableWidth = () => {
    const container = document.getElementById("table-header-wrap");
    const docsContainer = document.getElementById("doc-table-headers");
    if (docsContainer) {
      tableWidth.value = docsContainer.clientWidth;
      return;
    } else if (container) {
      tableWidth.value = container.clientWidth;
    }
  };

  const getFormattedHeaders = (headers: headerMin[], visibleHeaders: headerMin[], difference: number) => {
    if (getFormattedHeadersCb) {
      return getFormattedHeadersCb(headers, visibleHeaders, difference);
    }
    if (visibleHeaders.length && !isNotStretchLastHeader) {
      const lastVisibleItemValue = visibleHeaders[visibleHeaders.length - 1]?.value ?? "";
      headers.map((item: headerMin) => {
        if (item.value === lastVisibleItemValue) {
          item.width = item?.defaultWidth + difference;
        }
        return item;
      });
    }
    return headers;
  };

  onMounted(() => {
    Vue.nextTick(() => {
      getTableWidth();
    });
  });

  return {
    headers,
    stretchHeaders,
    getTableWidth,
    getFormattedHeaders,
  };
};

export default useTableCells;
