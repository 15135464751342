


































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DatePicker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    attach: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "",
    },
    isTop: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "чч:мм",
    },
    value: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    changeDate(value: string) {
      this.menu = false;
      this.$emit("change", value || "");
    },
  },
});
