




































































































import { defineComponent, ComponentInstance, computed } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { convertApiItemToUi } from "@monorepo/reports/src/views/PassportArchiveStoreReportView/utils/convertApiItemToUi";
import { fields } from "@monorepo/utils/src/variables/projectsData/passportArchiveStoreReport/fields";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";

const SECTION_NAME = "passportArchiveStoreReportView";

type headerMin = { width: number; id: string; value: string; isTextAlignCenter?: boolean; isHandle?: boolean };
export default defineComponent({
  data() {
    return {
      fields,
    };
  },
  props: {
    tableHeaders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(SECTION_NAME, ["data"]),
    ...mapGetters(SECTION_NAME, { getCells: "cells" }),
    headers(this: { stretchHeaders: (tableHeaders: headerMin[]) => headerMin[]; activeCells: string[]; tableHeaders: headerMin[] }): headerMin[] {
      return [...this.stretchHeaders(this.tableHeaders)];
    },
    resultData(): ReturnType<typeof convertApiItemToUi> {
      return convertApiItemToUi(this.data || {});
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const tableHeaders = computed(() => props.tableHeaders as unknown[]);
    const { stretchHeaders } = useTableCells(root, SECTION_NAME, tableHeaders);
    return {
      stretchHeaders,
    };
  },
});
