import { ComponentInstance } from "@vue/composition-api";
import { Route } from "vue-router";

const useSetStartFilters = async (root: ComponentInstance, moduleName: string, names: string[], autocompleteNames?: string[]): Promise<void> => {
  const query = (root.$route as Route).query || {};
  const store = root.$store;

  if (query.isOpenModal) {
    store.dispatch(`${moduleName}/addOpenedId`, null);
  }

  names.forEach((name) => {
    if (query[name]) {
      store.dispatch(`${moduleName}/addFilter`, { [name]: query[name] });
    }
  });

  if (autocompleteNames) {
    autocompleteNames.forEach((name) => {
      if (query[name]) {
        store.dispatch(`${moduleName}/addFilter`, {
          [name]: {
            titleValue: query[name],
            [name]: query[name],
          },
        });
      }
    });
  }
};

export default useSetStartFilters;
