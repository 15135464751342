import { ComponentInstance, onMounted, onUnmounted, Ref, computed } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { FILTER_TEMPLATES_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/filterTemplates";
import { IActionType } from "@monorepo/utils/src/eventBus/types/action";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";

const useTemplateFilters = (
  root: ComponentInstance,
  moduleName: string,
  currentFilters: Ref<Record<string, unknown>>,
  customFieldNames?: { searchTemplates?: string; saveSearchTemplates?: string }
): void => {
  const store = root.$store;

  const searchTemplates = computed(
    () => (store.getters[`${moduleName}/${customFieldNames?.searchTemplates || "searchTemplates"}`] || []) as { id: string; value: string }[]
  );

  const saveSearchTemplates = (packet: ISearchTemplate) => {
    return store.dispatch(`${moduleName}/${customFieldNames?.saveSearchTemplates || "saveSearchTemplates"}`, packet);
  };

  const restoreFiltersFromTemplateEventEmit = (packet: IActionType<ISearchTemplate>) => {
    const currentFiltersObj = JSON.parse(searchTemplates.value?.find((item) => item.id === packet.payload.id)?.value || "{}");
    if (currentFiltersObj) {
      currentFilters.value = currentFiltersObj;
    }
  };

  const saveFiltersToTemplate = (packet: IActionType<ISearchTemplate>) => {
    saveSearchTemplates({ ...packet.payload, value: JSON.stringify(currentFilters.value) });
  };

  onMounted(() => {
    eventBus.$on(FILTER_TEMPLATES_BUS_ACTIONS.RESTORE_FILTER, restoreFiltersFromTemplateEventEmit);
    eventBus.$on(FILTER_TEMPLATES_BUS_ACTIONS.SAVE_FILTERS, saveFiltersToTemplate);
  });

  onUnmounted(() => {
    eventBus.$off(FILTER_TEMPLATES_BUS_ACTIONS.RESTORE_FILTER, restoreFiltersFromTemplateEventEmit);
    eventBus.$off(FILTER_TEMPLATES_BUS_ACTIONS.SAVE_FILTERS, saveFiltersToTemplate);
  });
};

export default useTemplateFilters;
