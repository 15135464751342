export const elementStatuses = [
  {
    title: "Активные",
    value: "Активные",
    bool: false,
  },
  {
    title: "Удаленные",
    value: "Удаленные",
    bool: true,
  },
];
